import React from 'react';
import { Field } from 'loxamtraining-form';
import { FormGroup, InputProps, FormText } from 'reactstrap';
import { FieldProps as FieldFormikProps } from 'formik';

interface RadioFieldComponentProps extends FieldFormikProps, InputProps {
  label?: string;
  helptext?: string;
  name: string;
  form: any;
  options?: any[];
}

const buttonStyle = {
  fontFamily: "Arial",
  borderRadius: "18px",
  border: "solid 1px transparent",
  backgroundColor: "#1c89ff"
};

const ButtonFieldComponent: React.FC<RadioFieldComponentProps> = ({
     field,
     form: { touched, errors, setFieldValue, values },
     ...props
   }) => {
  const handleClick = () => {
    !props.disabled && setFieldValue(field.name, props.value)
  }

  return (
    <FormGroup className="mb-0">
      <div onClick={handleClick}>
        <button onClick={handleClick} className="btn btn-dark btn-xs" type="button" disabled={values[field.name] === props.value}>
          {
            values[field.name] === props.value ? "Sélectionné" : "Choisir"
          }
        </button>
      </div>
      {touched[field.name] && errors[field.name] && (
              <FormText color="error" className="invalid-feedback">
                {errors[field.name]}
              </FormText>
      )}
      {props.helptext && <FormText>{props.helptext}</FormText>}
    </FormGroup>
  );
};

const ButtonField = (props: any) => (
  <Field component={ButtonFieldComponent} {...props} />
);

export default ButtonField;
