import React from 'react'
import { Container } from 'reactstrap'

const NotFound = () => (
    <section className="py-12">
      <Container>
      <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
            <div className="mb-7 font-size-h1">🙁</div>
            <h2 className="mb-5">Page introuvable</h2>
          </div>
      </div>
      </Container>
    </section>
)

export default NotFound