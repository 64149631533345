import React, { useState, useContext } from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Form, TextField, SelectField, EmailField } from 'loxamtraining-form'
import { Redirect } from 'react-router-dom'
import { BusinessEntityField } from './index'
import { UserContext } from '../../utils/contexts'
import { createAccount } from '../../services/loxam.services'
import { useQuery } from '../../utils/hooks'
import loxamLogo from '../../assets/img/loxam/loxam-logo.svg'
import ciclogo from '../../assets/img/loxam/Kiosque_a_service.png'
import Loading from "../../components/Loading";

const CreateAccountForm = (props: any) => {
  const {isLoading} = props
  return (
    <>
      <Form initialValues={props.initialValues} onSubmit={props.onSubmit} buttonName={"Créer mon compte"}>
        <fieldset>
          <BusinessEntityField isLoading={isLoading}/>
        </fieldset>
        <fieldset>
          <SelectField name="gender" label="Civilité" className="custom-select" required>
            <option value=""></option>
            <option value="Mme">Madame</option>
            <option value="M">Monsieur</option>
          </SelectField>
          <Row>
            <Col md="6">
              <TextField name="firstName" label="Prénom" required />
            </Col>
            <Col md="6">
              <TextField name="lastName" label="Nom" required />
            </Col>
          </Row>
          <EmailField name="email" label="Email" required />
        </fieldset>
        { isLoading ?
          ( <div style={{height: '54px', marginTop: '10px'}}><Loading /> Création en cours...</div> )
          :
          (
            <></>
          )
        }
      </Form>
    </>
  )
}


const SucessSection = () => (
  <section className="py-12">
    <Container>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8 col-xl-6 text-center">
          <div className="mb-7 font-size-h1">Félicitation !</div>
          <h2 className="mb-5">Votre compte a été créé<br />avec succès.</h2>
          <p className="mb-7 text-gray-500">
            Vous allez recevoir un mail pour confirmer la création de votre compte.<br />
            Dès maintenant aller sur loxam.fr pour réserver votre matériel.
          </p>
          <a className="btn btn-primary" href="https://www.loxam.fr/login">
            Accéder au site loxam.fr
          </a>
        </div>
      </div>
    </Container>
  </section>
)

const CreateAccountSection = ({ setAccountCreated }: {setAccountCreated :any}) => {
  const queryParams: any = useQuery()
  const user: any = useContext(UserContext)
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)

  const handleSubmit = async (values: any, actions: any) => {
    actions.setStatus(null)
    if (values.entity === null) {
      actions.setStatus('Veuillez rechercher et choisir une entreprie pour finaliser la création de votre compte.')
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      actions.setSubmitting(false)
    } else {
      setIsLoading(true)
      let data
      try {
        data = await createAccount(values, user.token)
        actions.setSubmitting(false)
        setAccountCreated(true)
        setIsLoading(false)
      }catch (e){
        console.log(e.response)
        if(e.response.data.code === 10){
          actions.setStatus("Un contact existe déjà avec le nom ou l'adresse email renseigné.")
        }else{
          actions.setStatus('Erreur lors de la création du compte.')
        }
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        setIsLoading(false)
      }
    }
  }

  const lead: any = [...queryParams.entries()].reduce((acc: any, param: any) => {
    acc[param[0]] = param[1]
    return acc
  }, {})

  const initialValues: any = {
    gender: lead.civility || '',
    firstName: lead.firstName || '',
    lastName: lead.lastName || '',
    email: lead.email || '',
    entity: null,
    siret: ''
  }

  return (
    lead.idLead ? (
      <section className="py-4">
        <Container>
          <Row>
            <Col md={{size: 8, offset: 2}}>
              <div className="card card-lg">
                <div className="card-body">
                  <h6>Créez votre compte Loxam pour bénéficier de vos tarifs préférentiels</h6>
                </div>
              </div>
            </Col>
            <Col md={{size: 8, offset: 2}}>
              <div className="card card-lg">
                <div className="card-body">
                  <CreateAccountForm initialValues={initialValues} isLoading={isLoading} onSubmit={handleSubmit} />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    ) : <Redirect to="not-found" />
  )
}

const CreateAccount = () => {
  const [accountCreated, setAccountCreated] : [boolean, any] = useState(false)

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light @@classList">
        <Container>
          <a className="navbar-brand mx-auto d-lg-block" href="#home">
            <img className="mr-8" src={loxamLogo} alt="loxamLogo" height="40" />
            <img src={ciclogo} alt="cicLogo" height="40" />
          </a>
        </Container>
      </nav>
      {
        accountCreated ? <SucessSection /> : <CreateAccountSection setAccountCreated={setAccountCreated} />
      }
    </>
  )
} 


export default CreateAccount