import React, { useState } from 'react'
import moment from 'moment'
import { FieldProps as FieldFormikProps } from 'formik'
import { FormGroup, InputProps, FormText, Label } from 'reactstrap'
import Field from '../Field'

const DatePicker = require('reactstrap-date-picker')

moment.locale('fr')

interface FieldProps extends FieldFormikProps, InputProps {
  label?: string,
  helpText?: string,
  name: string,
  form: any,
  time: string
}

const frenchDays = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam']
// eslint-disable-next-line max-len
const frenchMonths = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']


const OurDatePickerComponent: React.FC<FieldProps> = ({
  field,
  form: { touched, errors, setFieldValue },
  handleValueChange,
  ...props
}) => {
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState(props.time)

  const setDateValue = (date: string) => {
    setValue(date)
    setFieldValue(field.name, date)
    if (handleValueChange) handleValueChange(date)
  }
  return (
    <FormGroup>
      {props.label && <Label for={props.id}>{props.label} {props.required && "*"}</Label>}
      <DatePicker
        autoComplete="off"
        dateFormat="DD/MM/YYYY"
        placeholder="JJ/MM/AAAA"
        weekStartsOn={1}
        dayLabels={frenchDays}
        monthLabels={frenchMonths}
        onChange={(date: any) => setDateValue(date)} // PropTypes.func.isRequired
        value={value}
        focused={focused} // PropTypes.bool
        onFocusChange={({ focused }: any) => focused !== null && setFocused(focused)} // PropTypes.func.isRequired
        id={field.name} // PropTypes.string.isRequired,
        {...props}
      />
      {touched[field.name] && errors[field.name] && <FormText color="error" className="invalid-feedback">{errors[field.name]}</FormText>}
      {props.helpText && <FormText>{props.helpText}</FormText>}
    </FormGroup>
  )
}

const DatePickerFld = (props: any) => <Field component={OurDatePickerComponent} {...props} />

export default DatePickerFld
