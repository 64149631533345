import React, {useContext, useEffect, useState} from "react";
import {UserContext} from "../../utils/contexts";
import {useFormikContext} from "formik";
import {getBusinessEntity} from "../../services/loxam.services";
import Loading from "../../components/Loading";
import {ButtonField} from "../../components/Fields";

const BusinessEntityField = (props: any) => {
  const [entities, setEntities]: [any[], any] = useState([])
  const [isLoading, setIsLoading]: [boolean, any] = useState(false)
  const [searchSiretValue, setSearchSiretValue]: [string, any] = useState('')

  const user: any = useContext(UserContext)
  const { setFieldValue, initialValues }: any = useFormikContext()

  const searchRequest = async (siret?: string) => {
    let data
    if (user && searchSiretValue !== '') {
      setIsLoading(true)
      try {
        data = await getBusinessEntity(searchSiretValue, user.token)
        setEntities(data)
        setIsLoading(false)
      }catch (e){
        setEntities([])
        setValue(null)
        console.log(e)
        setIsLoading(false)
      }
    } else if (siret) {
      setIsLoading(true)
      try {
        data = await getBusinessEntity(searchSiretValue, user.token)
        setEntities(data)
        setIsLoading(false)
      }catch (e){
        console.log(e)
        setEntities([])
        setValue(null)
        setIsLoading(false)
      }
    }
    if(data){
      setValue(data[0])
    }
  }

  const setValue = (value: any) => {
    setFieldValue('entity', value)
  }

  const handleSearch = async () => searchRequest()

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSearch()
    }
  }

  const clearResult = (event: any) => {
    event.preventDefault()
    setEntities([])
    setValue(null)
  }

  useEffect(() => {
    console.log('user: ', user)
    // eslint-disable-next-line
    const siret = initialValues.siret
    if (siret !== '') {
      setSearchSiretValue(siret)
      searchRequest(initialValues.siret)
    }
  }, [user])

  return (
    <>
      {
        entities.length === 0 && (
          <div className="form-group">
            <label>Rechercher votre entreprise *</label>
            { isLoading ?
              ( <div style={{height: '54px'}}><Loading /> Recherche en cours...</div> )
              :
              (
                <div className="input-group input-group-merge">
                  <input className="form-control" type="text" onKeyDown={handleKeyDown} placeholder="Siret, Siren, N° de TVA" value={searchSiretValue} onChange={(event: any) => setSearchSiretValue(event.target.value)}/>
                  <div className="input-group-append">
                    <button className="btn btn-outline-border" type="button" onClick={handleSearch}>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                </div>
              )
            }
          </div>
        )
      }

      {
        entities.length > 0 && isLoading === false && (
          <div className="form-group">
            <label>Sélectionnez votre entreprise {!props.isLoading && (<a href="#edit" onClick={clearResult}>(Modifier le Siret)</a>)}</label>
            <div style={{ maxHeight: '400px', overflow: 'auto' }}>
              <table className="table table-bordered table-sm table-hover mb-0">
                <tbody>
                {
                  entities.map((entity: any, index: number) => <BusinessUnitItem entity={entity} setValue={setValue} key={index} isLoading={props.isLoading}/>)
                }
                </tbody>
              </table>
            </div>
          </div>
        )
      }
    </>
  )
}

const BusinessUnitItem = ({entity, setValue, isLoading }: {entity: any, setValue: any, isLoading: boolean}) => {
  const { values } : any = useFormikContext()

  const handleClick = (value: any) => {
    if(!isLoading) setValue(value);
  };

  return (
    <tr
      style={values['entity'] === entity ? { cursor: 'default', color: 'grey', backgroundColor: '#f5f5f5'} : isLoading ? { cursor: 'default', backgroundColor: 'white'} : { cursor: 'pointer'} }
      onClick={() => handleClick(entity)}
    >
      <td>
        <span style={{ fontWeight: 700 }}>{entity.companyName}</span>
        <br />
        {entity.siret}
        <br />
        {entity.address1}
        <br />
        {entity.postalCode} {entity.city}
      </td>
      <td style={{ textAlign: 'center' }} className="align-middle">
        <ButtonField name="entity" value={entity} />
      </td>
    </tr>
  )
}

export default BusinessEntityField