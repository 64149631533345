import React, { useEffect, useState } from 'react'
import * as firebase from 'firebase/app'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import Loading from './components/Loading'

import { AuthClient } from './services'
import { config } from './config'
import {CreateAccount} from './pages/CreateAccount'
import NotFound from './pages/NotFound'
import { UserContext } from './utils/contexts'

firebase.initializeApp(config.firebase)


function App() {
  const [isLoading, setIsLoading]: [ boolean, any ] = useState(true)
  const [user, setUser]: [any, any] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    
    const authClient = new AuthClient()

    const initUser = async (user: any) => {
      if (user) {
        const token = await authClient.getIdToken()

        setUser({
          id: user.id,
          token: token
        })
        setIsLoading(false)
      } else {
        await authClient.createAnonymousUser()
      }
    }
    // Get the current user
    authClient.onCurrentUser((user: any) => {
      initUser(user)
    })
    
    // setUser({
    //   id: '2',
    //   token: 'token'
    // })
    setIsLoading(false)
  }, [])

  return (
    isLoading ? (
      <div className="text-center mt-12 pt-12">
        <Loading /> Chargement...
      </div>
      ) : (
      <Router>
        <UserContext.Provider value={user}>
          <Switch>
            <Route path="/" exact component={CreateAccount} />
            <Route path="/not-found" exact component={NotFound} />
          </Switch>
        </UserContext.Provider>
      </Router>
    )
  );
}

export default App;
