import React from 'react'
import { Field as FieldFormik } from 'formik'
import * as yup from 'yup'

//Return the validate function generated with
const handleValidate = (required: boolean, validationSchema?: any) => {
	return (value: any) => {
		let schema = validationSchema ? validationSchema : typeof value === 'string' ? yup.string() : yup.mixed()

		if (required) {
			schema = schema.required('Le champ est obligatoire')
		}

		return schema.validate(value)
				.then(() => { return })
				.catch((error: any) => error.message)
	}
}

interface FormFielProps {
	component: any,
	required: boolean,
	validationSchema: any,
	props: any
}


const Field: React.FC<FormFielProps> = ({component, required, validationSchema, ...props}) => <FieldFormik component={component} required={required} {...props} validate={handleValidate(required, validationSchema)} />


export default Field