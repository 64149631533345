import axios from 'axios'

const getBusinessEntity = async (siret: string, token: string) => {
  const url = process.env.REACT_APP_PUNCHOUT_API_BASE_URL + "/account/searchBusinessUnit"
  const entities: any = await axios.get(
    url, {
      params: {
        searchValue: siret
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })

  return entities.data
}

const createAccount = async (values: any, token: string) => {
  const param = {
    contactDto: {
      gender: values.gender === 'M' ? 'Monsieur' : 'Madame',
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      adresse: {
        address1: values.entity.address1,
        // address2: "string",
        // address3: "string",
        postalCode: values.entity.postalCode,
        city: values.entity.city,
      }
    },
    unitDto: {
      idEllisphere: values.entity.siret
    }
  }
  console.log('param', param)
  const url = process.env.REACT_APP_PUNCHOUT_API_BASE_URL + "/account"
  const result = await axios.post(
    url,
  {
    contactDto: param.contactDto,
    unitDto: param.unitDto,
    headers: {
      'Authorization': `Bearer ${token}`
  }})
}

export { getBusinessEntity, createAccount }