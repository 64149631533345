import React from 'react'
import { Formik } from 'formik'

import { Form as FormStrap, Alert } from 'reactstrap'

interface FormProps {
	initialValues: any,
	onSubmit: any,
	buttonName?: string
	buttonClassName?:string,
	hideSubmitButton?: boolean
	validate?: any,
	enableReinitialize?: boolean
}

const Form: React.FC<FormProps> = (props) => {
	return (
		<Formik initialValues={props.initialValues} onSubmit={props.onSubmit} validate={props.validate} enableReinitialize={props.enableReinitialize} >
			{
				(formikProps) => (
					<FormStrap onSubmit={formikProps.handleSubmit} autocomplete="off">
						{formikProps.status && (<Alert color="danger">{formikProps.status}</Alert>)}
						<fieldset disabled={formikProps.isSubmitting}>
							{props.children}
						</fieldset>
						{
							!props.hideSubmitButton && (
								<button type="submit" className={props.buttonClassName ? props.buttonClassName : "btn btn-primary" } disabled={formikProps.isSubmitting}>
									{props.buttonName ? props.buttonName : "Enregistrer"}
								</button>
							)
						}
					</FormStrap>
				)
			}
		</Formik>
	)
}


export default Form